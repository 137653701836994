import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UIFilter } from '../filters.component';
import { ColumnType } from 'src/app/data-warehouse/dw-column';
import { getPeopleColumnByName } from '../../people-columns';

interface FilterGroup {
  operator: 'and' | 'or';
  filters: Array<UIFilter | FilterGroup>;
  isPastJobsGroup?: boolean;
}

interface RemoveGroupEvent {
  group: FilterGroup;
  parentGroup?: FilterGroup;
}

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent {
  @Input() group: FilterGroup = { operator: 'and', filters: [] };
  @Input() isRoot: boolean = false;
  @Input() parentGroup?: FilterGroup;
  @Input() depth: number = 0;
  @Input() parentOperator: string = '';
  @Input() isPastJobsGroup: boolean = false; // Default to false

  @Output() filterAdded = new EventEmitter<{
    component: FilterGroupComponent;
    isPastJobsGroup: boolean;
  }>();
  @Output() removeGroup = new EventEmitter<RemoveGroupEvent>();
  @Output() groupAdded = new EventEmitter<FilterGroup>();

  ngOnInit(): void {
    if (!this.group) {
      this.group = { operator: 'and', filters: [] };
    }
  }

  // add a new simple filter, it opens filter dialog selector
  addFilter(isPastJobsGroup: boolean): void {
    this.filterAdded.emit({ component: this, isPastJobsGroup });
  }

  isSimpleFilter(item: UIFilter | FilterGroup): boolean {
    const filter = (item as UIFilter).filter;
    if (filter === undefined) {
      return false;
    }

    const col = getPeopleColumnByName(filter.field!);
    return col.type !== ColumnType.companySearch;
  }

  isCompanySearchFilter(item: UIFilter | FilterGroup): boolean {
    const filter = (item as UIFilter).filter;
    if (filter === undefined) {
      return false;
    }

    const col = getPeopleColumnByName(filter.field!);
    return col.type === ColumnType.companySearch;
  }

  isFilterGroup(item: UIFilter | FilterGroup): item is FilterGroup {
    return (item as FilterGroup).operator !== undefined;
  }

  onRemoveItem(index: number) {
    this.group.filters.splice(index, 1);
  }

  addGroup() {
    if (this.group && this.group.filters) {
      const newGroup: FilterGroup = {
        operator: 'and',
        filters: [],
        isPastJobsGroup: false, // default behavior for new groups
      };
      this.group.filters.push(newGroup);
      this.groupAdded.emit();
    } else {
      console.error('Attempted to add a group, but the group or filters array is undefined.');
    }
  }

  onDeleteGroup() {
    this.removeGroup.emit({ group: this.group, parentGroup: this.parentGroup });
  }

  isEmptyGroup(): boolean {
    return this.group.filters.length === 0;
  }

  isEvenDepth(): boolean {
    return this.depth % 2 === 0;
  }
}
