import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AdministrativeService, GetServices200ResponseInner } from 'ldt-identity-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-service-account-create-dialog',
  templateUrl: './service-account-create-dialog.component.html',
  styleUrls: ['./service-account-create-dialog.component.scss'],
})
export class ServiceAccountCreateDialogComponent implements OnInit {
  form: UntypedFormGroup;
  services: GetServices200ResponseInner[];

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ServiceAccountCreateDialogComponent>,
    private isaService: AdministrativeService,
    private notify: NotificationService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      serviceId: ['', [Validators.required]],
      role: ['', [Validators.required]],
      description: ['', []],
    });

    this.isaService.getServices().subscribe({
      next: (r: any) => {
        this.services = r.sort((a: any, b: any) => a.name.localeCompare(b.name));
      },
      error: (e: any) => {
        this.notify.error('Error getting services. Try again later.');
        this.close();
      },
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
