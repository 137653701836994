import { ColDef, ColGroupDef } from 'ag-grid-community';

export const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  flex: 1,
  minWidth: 100,
  suppressMenu: true,
};

export const columnDefs: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Person Info',
    children: [
      { field: 'name' },
      { field: 'country' },
      { field: 'location' },
      {
        field: 'linkedin',
        cellRenderer: (params: { value: any }) => {
          if (!params.value) {
            return '';
          } else {
            return (
              '<a href="https://www.linkedin.com/in/' +
              params.value +
              '" target=_blank>' +
              params.value +
              '</a>'
            );
          }
        },
        headerTooltip:
          'The LinkedIn ID of this person. Always uses the customer-provide ID, if provided. If not provided, ID was discovered by Live Data.',
      },
    ],
  },
  {
    headerName: 'Current Job',
    children: [
      { field: 'position.company.name', headerName: 'Company Name' },
      {
        field: 'position.title',
        headerName: 'Title',
        headerTooltip: 'The verified title this person currently holds',
      },
      {
        field: 'position.level',
        headerName: 'Job Level',
        headerTooltip: 'The inferred level of the current job, based on the title.',
      },
      {
        field: 'position.function',
        headerName: 'Job Function',
        headerTooltip: 'The inferred domain of the current job, based on the title.',
      },
      {
        field: 'position.started_at',
        headerName: 'Start Date',
        headerTooltip: 'The date the person started at their current job',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any) => {
          if (!params?.data?.position?.started_at) return '';

          if (params?.data?.position?.metadata?.started_at_year_only)
            return !params?.data?.position?.started_at.substr(0, 4);

          return params?.data?.position?.started_at.substr(0, 10);
        },
      },
      { field: 'position.location', headerName: 'Job Location' },
    ],
  },
  {
    headerName: 'Company Firmo',
    children: [
      {
        field: 'position.company.linkedin',
        headerName: 'LinkedIn',
        cellRenderer: (params: { value: any }) => {
          if (!params.value) {
            return '';
          } else {
            return (
              '<a href="https://www.linkedin.com/company/' +
              params.value +
              '" target=_blank>' +
              params.value +
              '</a>'
            );
          }
        },
      },
      { field: 'position.company.domain', headerName: 'Domain' },
      { field: 'position.company.ticker', headerName: 'Ticker' },

      { field: 'position.company.country', headerName: 'Country' },
      {
        field: 'position.company.employee_count',
        headerName: 'Employees',
      },
      { field: 'position.company.industry', headerName: 'Industry' },

      { field: 'position.company.location', headerName: 'Location' },
      {
        field: 'position.company.type',
        headerName: 'Type',
      },
    ],
  },
];
