import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-people-add-quick-build',
  templateUrl: './add-quick-build-modal.component.html',
  styleUrls: ['./add-quick-build-modal.component.scss'],
})
export class AddQuickBuildModalComponent implements OnInit {
  form: FormGroup;
  name: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddQuickBuildModalComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: [this.name, [Validators.required]],
    });
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close({
        name: this.form.get('name')?.value,
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
