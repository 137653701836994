import { DWColumn, ColumnType } from '../data-warehouse/dw-column';

export interface SearchFilterOperator {
  key: string;
  displayName: string;
  inputsRequired: number;
  types: ColumnType[];
}

export function getPeopleColumnByName(name: string): DWColumn {
  const col = PeopleColumns.find((col) => col.name === name);
  if (!col) {
    throw new Error(`Column ${name} not found`);
  }

  return col;
}

export const PeopleColumns: DWColumn[] = [
  {
    name: 'position_search',
    displayName: 'Company (Current Job)',
    type: ColumnType.companySearch,
    group: 'current job',
    isPopular: true,
  },
  {
    name: 'jobs_search',
    displayName: 'Company (Past Jobs)',
    type: ColumnType.companySearch,
    group: 'past jobs',
    isPopular: true,
  },
  {
    name: 'company_change_detected_at',
    displayName: 'Company Change Detected',
    type: ColumnType.date,
    group: 'metadata',
  },
  {
    name: 'connections',
    displayName: 'Number of Connections',
    type: ColumnType.number,
    group: 'personal',
  },
  {
    name: 'country',
    displayName: 'Country',
    type: ColumnType.string,
    group: 'personal',
    isPopular: true,
  },
  { name: 'id', displayName: 'Person ID', type: ColumnType.string, group: 'personal' },
  {
    name: 'info_change_detected_at',
    displayName: 'Any Info Changed',
    type: ColumnType.date,
    group: 'metadata',
  },
  {
    name: 'linkedin',
    displayName: 'LinkedIn Profile',
    type: ColumnType.string,
    group: 'personal',
  },
  {
    name: 'location',
    displayName: 'Location',
    type: ColumnType.string,
    group: 'personal',
    isPopular: true,
  },
  { name: 'name', displayName: 'Full Name', type: ColumnType.string, group: 'personal' },
  { name: 'first_name', displayName: 'First Name', type: ColumnType.string, group: 'personal' },
  { name: 'last_name', displayName: 'Last Name', type: ColumnType.string, group: 'personal' },
  {
    name: 'cell_phone_number',
    displayName: 'Cell Phone',
    type: ColumnType.string,
    group: 'personal',
  },
  {
    name: 'position.company.id',
    displayName: 'LDT Company ID',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.company.address',
    displayName: 'Company Address',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.company.country',
    displayName: 'Company Country',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.company.employee_count',
    displayName: 'Company Employee Count',
    type: ColumnType.number,
    group: 'current job',
  },
  {
    name: 'position.company.industry',
    displayName: 'Company Industry',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.company.linkedin',
    displayName: 'Company LinkedIn ID',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.company.location',
    displayName: 'Company Location',
    type: ColumnType.string,
    group: 'current job',
    isPopular: true,
  },
  {
    name: 'position.company.naics_codes',
    displayName: 'Company NAICS Codes',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.company.name',
    displayName: 'Company Name',
    type: ColumnType.string,
    group: 'current job',
    isPopular: true,
  },
  {
    name: 'position.company.sic_codes',
    displayName: 'Company SIC Codes',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.company.ticker',
    displayName: 'Company Ticker',
    group: 'current job',
    type: ColumnType.string,
  },
  {
    name: 'position.company.type',
    displayName: 'Company Type',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.company.domain',
    displayName: 'Company Domain',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.direct_dial_number',
    displayName: 'Direct Dial Number',
    type: ColumnType.string,
    group: 'current job',
  },
  { name: 'position.email', displayName: 'Email', type: ColumnType.string, group: 'current job' },
  {
    name: 'position.email_status',
    displayName: 'Email Status',
    type: ColumnType.string,
    group: 'current job',
  },
  {
    name: 'position.ended_at',
    displayName: 'Job Ended Date',
    type: ColumnType.date,
    group: 'current job',
    isPopular: true,
  },
  {
    name: 'position.function',
    displayName: 'Job Function',
    type: ColumnType.jobfunction,
    group: 'current job',
  },
  {
    name: 'position.level',
    displayName: 'Job Level',
    type: ColumnType.joblevel,
    group: 'current job',
    isPopular: true,
  },
  {
    name: 'position.location',
    displayName: 'Location',
    type: ColumnType.string,
    group: 'current job',
    isPopular: true,
  },
  {
    name: 'position.started_at',
    displayName: 'Job Started Date',
    type: ColumnType.date,
    group: 'current job',
    isPopular: true,
  },
  {
    name: 'position.title',
    displayName: 'Title',
    type: ColumnType.string,
    group: 'current job',
    isPopular: true,
  },
  {
    name: 'position.started_at_year_only',
    displayName: 'Job Started At Year Only',
    type: ColumnType.boolean,
    group: 'current job',
  },
  {
    name: 'position.ended_at_year_only',
    displayName: 'Job Ended At Year Only',
    type: ColumnType.boolean,
    group: 'current job',
  },
  {
    name: 'jobs.company.id',
    displayName: 'LDT Company ID',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.address',
    displayName: 'Company Address',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.country',
    displayName: 'Company Country',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.employee_count',
    displayName: 'Company Employee Count',
    type: ColumnType.number,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.industry',
    displayName: 'Company Industry',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.linkedin',
    displayName: 'Company LinkedIn ID',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.location',
    displayName: 'Company Location',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.naics_codes',
    displayName: 'Company NAICS Codes',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.name',
    displayName: 'Company Name',
    type: ColumnType.string,
    group: 'past jobs',
    isPopular: true,
  },
  {
    name: 'jobs.company.sic_codes',
    displayName: 'Company SIC Codes',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.ticker',
    displayName: 'Company Ticker',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.type',
    displayName: 'Company Type',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.company.domain',
    displayName: 'Company Domain',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.direct_dial_number',
    displayName: 'Direct Dial Number',
    type: ColumnType.string,
    group: 'past jobs',
  },
  { name: 'jobs.email', displayName: 'Email', type: ColumnType.string, group: 'past jobs' },
  {
    name: 'jobs.email_status',
    displayName: 'Email Status',
    type: ColumnType.string,
    group: 'past jobs',
  },
  {
    name: 'jobs.ended_at',
    displayName: 'Job Ended At',
    type: ColumnType.date,
    group: 'past jobs',
  },
  {
    name: 'jobs.function',
    displayName: 'Job Function',
    type: ColumnType.jobfunction,
    group: 'past jobs',
  },
  { name: 'jobs.level', displayName: 'Job Level', type: ColumnType.joblevel, group: 'past jobs' },
  { name: 'jobs.location', displayName: 'Location', type: ColumnType.string, group: 'past jobs' },
  {
    name: 'jobs.started_at',
    displayName: 'Job Started At',
    type: ColumnType.date,
    group: 'past jobs',
    isPopular: true,
  },
  {
    name: 'jobs.title',
    displayName: 'Job Title',
    type: ColumnType.string,
    group: 'past jobs',
    isPopular: true,
  },
  {
    name: 'jobs.started_at_year_only',
    displayName: 'Job Started At Year Only',
    type: ColumnType.boolean,
    group: 'past jobs',
  },
  {
    name: 'jobs.ended_at_year_only',
    displayName: 'Job Ended At Year Only',
    type: ColumnType.boolean,
    group: 'past jobs',
  },
  {
    name: 'title_change_detected_at',
    displayName: 'Title Change Detected',
    type: ColumnType.date,
    group: 'metadata',
  },
  {
    name: 'education.degree',
    displayName: 'Education Degree Name',
    group: 'education',
    type: ColumnType.string,
  },
  {
    name: 'education.ended_at',
    displayName: 'Education End Date',
    type: ColumnType.date,
    group: 'education',
  },
  {
    name: 'education.field',
    displayName: 'Education Field',
    type: ColumnType.string,
    group: 'education',
  },
  {
    name: 'education.school',
    displayName: 'School Name',
    type: ColumnType.string,
    group: 'education',
  },
  {
    name: 'education.started_at',
    displayName: 'Education Start Date',
    type: ColumnType.date,
    group: 'education',
  },
];

export const SupportedOperators: { [key: string]: SearchFilterOperator } = {
  fuzzyMatch: {
    key: 'fuzzyMatch',
    displayName: 'Matches (fuzzy)',
    inputsRequired: 1,
    types: [ColumnType.string],
  },
  fuzzyNotMatch: {
    key: 'fuzzyNotMatch',
    displayName: 'Does not match (fuzzy)',
    inputsRequired: 1,
    types: [ColumnType.string],
  },
  equals: {
    key: 'equals',
    displayName: 'Equals',
    inputsRequired: 1,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
      ColumnType.companySearch,
    ],
  },
  notEqual: {
    key: 'notEqual',
    displayName: 'Does not equal',
    inputsRequired: 1,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
      ColumnType.companySearch,
    ],
  },
  blank: {
    key: 'blank',
    displayName: 'Blank',
    inputsRequired: 0,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
    ],
  },
  notBlank: {
    key: 'notBlank',
    displayName: 'Not blank',
    inputsRequired: 0,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
    ],
  },
  greaterThanOrEqual: {
    key: 'greaterThanOrEqual',
    displayName: 'Is greater than or equal to',
    inputsRequired: 1,
    types: [ColumnType.date, ColumnType.number],
  },
  lessThanOrEqual: {
    key: 'lessThanOrEqual',
    displayName: 'Is less than or equal to',
    inputsRequired: 1,
    types: [ColumnType.date, ColumnType.number],
  },
  lessThan: {
    key: 'lessThan',
    displayName: 'Is less than',
    inputsRequired: 1,
    types: [ColumnType.number],
  },
  greaterThan: {
    key: 'greaterThan',
    displayName: 'Is greater than',
    inputsRequired: 1,
    types: [ColumnType.number],
  },
  inRange: {
    key: 'inRange',
    displayName: 'Is in range',
    inputsRequired: 2,
    types: [ColumnType.date, ColumnType.number],
  },
};
