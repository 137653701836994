import { Injectable } from '@angular/core';
import { Company, CompanyPagesService, GetCompanyPagesRequest } from 'ldt-moneyball-api';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyInfoService {
  private maxCacheSize = 500; // Maximum number of cached items allowed
  private companyCache: Map<string, Company> = new Map();

  constructor(private companyPagesService: CompanyPagesService) {}

  // Add the company to the cache
  private addToCache(key: string, response: any): void {
    if (this.companyCache.size >= this.maxCacheSize) {
      const firstKey = this.companyCache.keys().next().value;
      this.companyCache.delete(firstKey);
    }
    this.companyCache.set(key, response);
  }

  public getCompanyInfo(companyId: string) {
    if (this.companyCache.has(companyId)) {
      return of(this.companyCache.get(companyId));
    }

    const req: GetCompanyPagesRequest = {
      report_name: GetCompanyPagesRequest.ReportNameEnum.Search,
      search_string: companyId,
    };
    return this.companyPagesService.getCompanyPages(req).pipe(
      catchError(() => {
        return of(undefined);
      }),
      map((response: any) => {
        if (
          response.companies &&
          response.companies instanceof Array &&
          response.companies.length > 0
        ) {
          this.addToCache(companyId, response.companies[0]);
          return response.companies[0];
        } else {
          return undefined;
        }
      })
    );
  }
}
