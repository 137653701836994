import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import {
  CreateServiceAccount200Response,
  GetServiceAccounts200ResponseInner,
  Org,
  RegenerateClientSecret200Response,
  ServiceAccount,
  ServiceAccountsService,
} from 'ldt-identity-service-api';
import { ColDef, GridApi } from 'ag-grid-community';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { SaCreateDialogComponent } from './sa-create-dialog/sa-create-dialog.component';
import { SaNewSecretDialogComponent } from './sa-new-secret-dialog/sa-new-secret-dialog.component';

@Component({
  selector: 'app-team-service-accounts',
  templateUrl: './team-service-accounts.component.html',
  styleUrls: ['./team-service-accounts.component.scss'],
  providers: [DatePipe],
})
export class TeamServiceAccountsComponent implements OnInit {
  @Input() org: Org;
  @Input() canAdmin: boolean;

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  private roleFilterParams: any = {
    filterOptions: [
      'empty',
      {
        displayKey: 'viewer',
        displayName: 'Viewer',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'viewer';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'editor',
        displayName: 'Editor',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'editor';
        },
        hideFilterInput: true,
      },
      {
        displayKey: 'admin',
        displayName: 'Admin',
        test: function (filterValue: any, cellValue: any) {
          return cellValue === 'admin';
        },
        hideFilterInput: true,
      },
    ],
    suppressAndOrCondition: true,
  };

  columnDefs: ColDef[] = [
    {
      field: '',
      headerName: '',
      width: 40,
      maxWidth: 40,
      checkboxSelection: true,
      sortable: false,
      filter: false,
      cellRenderer: (params: any) => {
        return '';
      },
    },
    {
      field: 'serviceAccount.clientId',
      headerName: 'ID',
      cellRenderer: 'loadingRenderer',
      maxWidth: 250,
      headerTooltip: 'Service Account ID',
    },
    { field: 'serviceAccount.name', headerName: 'Name', headerTooltip: 'Service Account Name' },
    {
      field: 'role.name',
      filterParams: this.roleFilterParams,
      headerTooltip: 'The role associated with this service account',
    },
    {
      field: 'serviceAccount.createdAt',
      headerName: 'Created (UTC)',
      sort: 'desc',
      headerTooltip: 'Time the service account was created',
      valueFormatter: (params: any) => {
        return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
      },
    },
    {
      headerName: 'Regenerate Secret',
      filter: false,
      maxWidth: 200,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.regenerateSecret.bind(this),
        label: 'Regenerate Secret',
      },
    },
  ];
  rowData: any[];
  rowSelection: string = 'single';
  tooltipShowDelay = 200;

  private gridApi: GridApi;
  private orgId: string = '';
  roles: string[] = ['viewer', 'editor', 'admin'];
  role: string;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  refreshing: boolean = true;

  constructor(
    private saService: ServiceAccountsService,
    private route: ActivatedRoute,
    private notify: NotificationService,
    public dialog: MatDialog,
    private legacyDialog: MatLegacyDialog,
    private datePipe: DatePipe
  ) {
    this.orgId = route.snapshot.paramMap.get('orgId') || '';
  }

  ngOnInit() {
    this.refreshOrgServiceAccounts();
  }

  refreshOrgServiceAccounts() {
    this.refreshing = true;
    this.saService.getServiceAccounts(this.org.id).subscribe({
      next: (r: GetServiceAccounts200ResponseInner[]) => {
        this.rowData = r;
        this.refreshing = false;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.rowData = [];
        this.refreshing = false;
      },
    });
  }

  rowsSelected: boolean = false;
  onSelectionChanged(event: any) {
    var selectedRows = this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }

  regenerateSecret(e: any) {
    let sa: ServiceAccount = e.rowData.serviceAccount;

    const confirmDialog = this.legacyDialog.open(DeleteConfirmationComponent, {
      width: '500px',
      data: {
        title: 'Confirm Secret Regeneration',
        message:
          'Are you sure you want to regenerate a secret for this service account? Your existing secret will immediately stop functioning.',
      },
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.saService.regenerateClientSecret(this.org.id, sa.clientId).subscribe({
          next: (r: RegenerateClientSecret200Response) => {
            this.showNewSecretDialog(
              r.serviceAccount.clientId,
              r.serviceAccount.clientSecret || ''
            );
          },
          error: () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          },
        });
      }
    });
  }

  removeSelected() {
    var ids: any = this.gridApi.getSelectedRows().map((d: any) => d.serviceAccount.clientId);
    const confirmDialog = this.legacyDialog.open(DeleteConfirmationComponent, {
      width: '500px',
      data: {
        title: 'Confirm Service Account Deletion',
        message:
          'Are you sure you want to delete this service account? This action is unrecoverable!',
      },
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.saService.deleteServiceAccount(this.orgId, ids[0]).subscribe({
          next: () => {
            this.gridApi.deselectAll();
            this.refreshOrgServiceAccounts();
            this.notify.success('Service account successfully deleted.');
          },
          error: () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          },
        });
      }
    });
  }

  showNewSecretDialog(clientId: string, clientSecret: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      orgId: this.orgId,
      clientId: clientId,
      clientSecret: clientSecret,
    };
    this.dialog.open(SaNewSecretDialogComponent, dialogConfig);
  }

  showCreateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(SaCreateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        let body = {
          name: data.name,
          role: data.role,
        };

        this.saService.createServiceAccount(this.org.id, body).subscribe({
          next: (r: CreateServiceAccount200Response) => {
            this.showNewSecretDialog(
              r.serviceAccount.clientId,
              r.serviceAccount.clientSecret || ''
            );
            this.refreshOrgServiceAccounts();
          },
          error: () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          },
        });
      }
    });
  }
}
