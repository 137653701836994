import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { ColumnType, DWColumn, JobFunctions, JobLevels } from 'src/app/data-warehouse/dw-column';
import { UIFilter } from '../filters.component';
import { SearchFilter } from 'ldt-people-api';
import * as dayjs from 'dayjs';
import {
  getPeopleColumnByName,
  SearchFilterOperator,
  SupportedOperators,
} from '../../people-columns';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-simple-filter',
  templateUrl: './simple-filter.component.html',
  styleUrls: ['./simple-filter.component.scss'],
})
export class SimpleFilterComponent {
  @Input() filter: UIFilter;
  @Input() operator: 'and' | 'or';
  @Input() depth: number = 0; // depth of the filter in the filter tree
  @Output() removeFilter = new EventEmitter<UIFilter>();
  @HostBinding('class') class =
    'simple-filter-component filter-row query-item tw-relative tw-flex tw-gap-1 tw-mb-5';

  ColumnType = ColumnType;
  column: DWColumn;

  jobLevelOptions = Object.values(JobLevels); // converts the JobLevels enum to an array of its values
  jobFunctionOptions = Object.values(JobFunctions); // converts the JobFunctions enum to an array of its values

  availableOperators: SearchFilterOperator[] = [];
  selectedOperator: SearchFilterOperator;

  firstInput: string;
  secondInput: string;
  listInput: string[];

  showMoreBadges: boolean = false;
  displayBadgeLimit: number = 5;
  displayedBadges: string[] = [];
  animateNum: boolean = false;

  constructor(private notify: NotificationService) {}
  ngOnInit() {
    try {
      this.column = getPeopleColumnByName(this.filter.filter.field!);
    } catch (e) {
      console.error('Filter field not found');
      return;
    }
    this.setAvailableOperatorsByType();
    this.loadFilterParams();
    this.updateDisplayedBadges();
    this.sortStringsAlphabetically();
  }

  // sort string values alphabetically so badges appear in order
  sortStringsAlphabetically(): void {
    if (this.column.type === ColumnType.string) {
      this.filter.filter.string_values = this.filter.filter.string_values?.sort();
    }
  }

  updateDisplayedBadges(): void {
    if (this.showMoreBadges) {
      this.displayedBadges = this.filter.filter.string_values
        ? this.filter.filter.string_values
        : [];
    } else {
      this.displayedBadges = this.filter.filter.string_values
        ? this.filter.filter.string_values.slice(0, this.displayBadgeLimit)
        : [];
    }
  }

  toggleShowMore() {
    this.showMoreBadges = !this.showMoreBadges;
    this.updateDisplayedBadges();
  }

  setAvailableOperatorsByType() {
    Object.keys(SupportedOperators).forEach((operator) => {
      if (!this.column.type) {
        console.error('Filter field type is not set');
        return;
      }
      if (SupportedOperators[operator].types.includes(this.column.type)) {
        this.availableOperators.push(SupportedOperators[operator]);
      }
    });
  }

  // When the filter sent in has values, use them. This will get tricky with the operations
  // and how we translate from UI into PersonSearchFilter. We can't exactly reverse-engineer that
  // back to the UI operator. We'll need to figure this out when we save and load these things for real
  loadFilterParams() {
    if (!this.filter.filter) {
      return;
    }

    const operator = this.getOperatorForField(this.column.type);
    this.selectedOperator = operator ? operator : this.availableOperators[0];

    switch (this.column.type) {
      case ColumnType.joblevel:
        this.listInput = this.filter.filter.string_values || [];
        break;
      case ColumnType.jobfunction:
        this.listInput = this.filter.filter.string_values || [];
        break;
      case ColumnType.string:
        break;
      case ColumnType.boolean:
        this.firstInput = this.filter.filter.string_values?.join(', ') || '';
        break;
      case ColumnType.number:
        this.firstInput = this.filter.filter.number_min?.toString() || '';
        this.secondInput = this.filter.filter.number_max?.toString() || '';
        break;
      case ColumnType.date:
        // Set a default date if this is a new filter
        if (!this.filter.filter.date_from && !this.filter.filter.date_to) {
          this.filter.filter.date_from = dayjs().format('YYYY-MM-DD');
          break;
        }

        // If not a new filter, set the inputs based on the operator
        switch (this.selectedOperator.key) {
          case 'equals':
          case 'notEqual':
          case 'greaterThanOrEqual':
            this.firstInput = dayjs(this.filter.filter.date_from).format('YYYY-MM-DD') || '';

            break;
          case 'lessThanOrEqual':
            this.firstInput = dayjs(this.filter.filter.date_to).format('YYYY-MM-DD') || '';

            break;
          case 'inRange':
            this.firstInput = dayjs(this.filter.filter.date_from).format('YYYY-MM-DD') || '';
            this.secondInput = dayjs(this.filter.filter.date_to).format('YYYY-MM-DD') || '';
            break;
          case 'blank':
          case 'notBlank':
          default:
            break;
        }

        break;
    }
  }

  getOperatorForField(fieldType: ColumnType | undefined): SearchFilterOperator | null {
    if (!fieldType) {
      return null;
    }

    const exists = this.filter.filter.match_type === SearchFilter.MatchTypeEnum.Exists;
    const exact = this.filter.filter.match_type === SearchFilter.MatchTypeEnum.Exact;
    const fuzzy = this.filter.filter.match_type === SearchFilter.MatchTypeEnum.Fuzzy;
    const must = this.filter.filter.type === SearchFilter.TypeEnum.Must;

    switch (this.column.type) {
      case ColumnType.joblevel:
      case ColumnType.jobfunction:
      case ColumnType.boolean:
      case ColumnType.string:
        if (must && fuzzy) {
          return SupportedOperators.fuzzyMatch;
        }
        if (!must && fuzzy) {
          return (this.selectedOperator = SupportedOperators.fuzzyNotMatch);
        }
        if (must && exact) {
          return (this.selectedOperator = SupportedOperators.equals);
        }
        if (!must && exact) {
          return (this.selectedOperator = SupportedOperators.notEqual);
        }
        if (!must && exists) {
          return (this.selectedOperator = SupportedOperators.blank);
        }
        if (must && exists) {
          return (this.selectedOperator = SupportedOperators.notBlank);
        }
        break;
      case ColumnType.number:
      case ColumnType.date:
        const has_lower = this.filter.filter.number_min || this.filter.filter.date_from;
        const has_upper = this.filter.filter.number_max || this.filter.filter.date_to;

        if (has_lower && has_upper) {
          return SupportedOperators.inRange;
        }

        if (has_lower && !has_upper) {
          return SupportedOperators.greaterThanOrEqual;
        }

        if (!has_lower && has_upper) {
          return SupportedOperators.lessThanOrEqual;
        }

        if (has_lower === has_upper) {
          return SupportedOperators.equals;
        }

        // TODO check with the backend
        break;
    }

    return null;
  }

  setFilterParams() {
    var match_type: SearchFilter.MatchTypeEnum = SearchFilter.MatchTypeEnum.Exact;
    var search_type: SearchFilter.TypeEnum = SearchFilter.TypeEnum.Must;

    switch (this.column.type) {
      case ColumnType.joblevel:
      case ColumnType.jobfunction:
        this.filter.filter.match_type = SearchFilter.MatchTypeEnum.Exact;
        this.filter.filter.type = SearchFilter.TypeEnum.Must;
        this.filter.filter.string_values = this.listInput;
        break;

      case ColumnType.string:
        if (!this.filter.filter.string_values) {
          this.filter.filter.string_values = [];
        }

        // No need to set firstInput, handle string_values directly
        switch (this.selectedOperator.key) {
          case 'fuzzyMatch':
            match_type = SearchFilter.MatchTypeEnum.Fuzzy;
            search_type = SearchFilter.TypeEnum.Must;
            break;
          case 'fuzzyNotMatch':
            match_type = SearchFilter.MatchTypeEnum.Fuzzy;
            search_type = SearchFilter.TypeEnum.MustNot;
            break;
          case 'equals':
            match_type = SearchFilter.MatchTypeEnum.Exact;
            search_type = SearchFilter.TypeEnum.Must;
            break;
          case 'notEqual':
            match_type = SearchFilter.MatchTypeEnum.Exact;
            search_type = SearchFilter.TypeEnum.MustNot;
            break;
          case 'blank':
            match_type = SearchFilter.MatchTypeEnum.Exists;
            search_type = SearchFilter.TypeEnum.MustNot;
            break;
          case 'notBlank':
            match_type = SearchFilter.MatchTypeEnum.Exists;
            search_type = SearchFilter.TypeEnum.Must;
            break;
        }

        this.filter.filter.match_type = match_type;
        this.filter.filter.type = search_type;
        break;

      case ColumnType.boolean:
        switch (this.selectedOperator.key) {
          case 'equals':
            match_type = SearchFilter.MatchTypeEnum.Exact;
            search_type = SearchFilter.TypeEnum.Must;
            this.filter.filter.boolean_value = this.firstInput === 'true';
            break;
          case 'notEqual':
            match_type = SearchFilter.MatchTypeEnum.Exact;
            search_type = SearchFilter.TypeEnum.MustNot;
            this.filter.filter.boolean_value = this.firstInput === 'true';
            break;
          case 'blank':
            match_type = SearchFilter.MatchTypeEnum.Exists;
            search_type = SearchFilter.TypeEnum.MustNot;
            break;
          case 'notBlank':
            match_type = SearchFilter.MatchTypeEnum.Exists;
            search_type = SearchFilter.TypeEnum.Must;
            break;
        }

        this.filter.filter.match_type = match_type;
        this.filter.filter.type = search_type;
        break;

      case ColumnType.number:
        var search_min: number | undefined;
        var search_max: number | undefined;

        this.filter.filter.number_min = undefined;
        this.filter.filter.number_max = undefined;

        switch (this.selectedOperator.key) {
          case 'equals':
            search_type = SearchFilter.TypeEnum.Must;
            search_min = +this.firstInput;
            search_max = +this.firstInput;
            break;
          case 'notEqual':
            search_type = SearchFilter.TypeEnum.MustNot;
            search_min = +this.firstInput;
            search_max = +this.firstInput;
            break;
          case 'lessThan':
            search_type = SearchFilter.TypeEnum.Must;
            search_max = +this.firstInput - 1;
            break;
          case 'lessThanOrEqual':
            search_type = SearchFilter.TypeEnum.Must;
            search_max = +this.firstInput;
            break;
          case 'greaterThan':
            search_type = SearchFilter.TypeEnum.Must;
            search_min = +this.firstInput + 1;
            break;
          case 'greaterThanOrEqual':
            search_type = SearchFilter.TypeEnum.Must;
            search_min = +this.firstInput;
            break;
          case 'inRange':
            search_type = SearchFilter.TypeEnum.Must;
            search_min = +this.firstInput;
            search_max = +this.secondInput;
            break;
          case 'blank':
            match_type = SearchFilter.MatchTypeEnum.Exists;
            search_type = SearchFilter.TypeEnum.MustNot;
            break;
          case 'notBlank':
            match_type = SearchFilter.MatchTypeEnum.Exists;
            search_type = SearchFilter.TypeEnum.Must;
            break;
        }

        this.filter.filter.type = search_type;

        if (search_min) this.filter.filter.number_min = search_min;
        if (search_max) this.filter.filter.number_max = search_max;

        break;

      case ColumnType.date:
        var match_type: SearchFilter.MatchTypeEnum = SearchFilter.MatchTypeEnum.Fuzzy;

        this.filter.filter.date_from = undefined;
        this.filter.filter.date_to = undefined;

        var search_from;
        var search_to;
        const parsed_from = dayjs(new Date(this.firstInput)).format('YYYY-MM-DD');
        const parsed_to = this.secondInput
          ? dayjs(new Date(this.secondInput)).format('YYYY-MM-DD')
          : undefined;

        switch (this.selectedOperator.key) {
          case 'equals':
            search_type = SearchFilter.TypeEnum.Must;
            search_from = parsed_from;
            search_to = parsed_from;
            break;
          case 'notEqual':
            search_type = SearchFilter.TypeEnum.MustNot;
            search_from = parsed_from;
            search_to = parsed_from;
            break;
          case 'lessThanOrEqual':
            search_type = SearchFilter.TypeEnum.Must;
            search_to = parsed_from;
            break;
          case 'greaterThanOrEqual':
            search_type = SearchFilter.TypeEnum.Must;
            search_from = parsed_from;
            break;
          case 'inRange':
            search_type = SearchFilter.TypeEnum.Must;
            search_from = parsed_from;
            search_to = parsed_to;
            break;
          case 'blank':
            match_type = SearchFilter.MatchTypeEnum.Exists;
            search_type = SearchFilter.TypeEnum.MustNot;
            break;
          case 'notBlank':
            match_type = SearchFilter.MatchTypeEnum.Exists;
            search_type = SearchFilter.TypeEnum.Must;
            break;
          default:
            search_type = SearchFilter.TypeEnum.Must;
            search_from = parsed_from;
            break;
        }

        this.filter.filter.match_type = match_type;
        this.filter.filter.type = search_type;

        if (search_from) this.filter.filter.date_from = search_from;
        if (search_to) this.filter.filter.date_to = search_to;

        break;
    }
  }

  onRemove() {
    this.removeFilter.emit(this.filter);
  }

  /**
   * Adds a new badge to the filter based on the input value when the Enter key is pressed.
   * @param {KeyboardEvent} event
   */
  addBadge(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value.trim();

    if (!value) return;

    // Split values on newline
    const values = value.split(/\r?\n/).map((v) => v.trim());
    this.filter.filter.string_values = (this.filter.filter.string_values || []).concat(values); // init if needed and push the new value

    this.updateDisplayedBadges();
    inputElement.value = '';

    // Trigger btn animations if badges are truncated
    if (!this.showMoreBadges) {
      this.animateNum = true;
      setTimeout(() => {
        this.animateNum = false;
      }, 300); // match the duration of the animation in html
    }
  }

  removeBadge(value: string): void {
    if (!this.filter.filter.string_values) {
      this.notify.error('No value to remove');
      return;
    }
    const index = this.filter.filter.string_values.indexOf(value);
    if (index >= 0) {
      this.filter.filter.string_values.splice(index, 1);
      this.updateDisplayedBadges();
    }
  }

  selectAllOptions() {
    this.jobFunctionOptions.forEach((item) => {
      this.listInput = [...this.jobFunctionOptions];
      this.setFilterParams();
    });
  }

  deselectAllOptions() {
    this.listInput = [];
    this.setFilterParams();
  }

  isEvenDepth(): boolean {
    return this.depth % 2 === 0;
  }
}
