<div class="login-invalid" *ngIf="loginInvalid">
  <img class="tw-w-10 tw-h-7" src="../../assets/icons/error.svg" alt="error" />
  <span>You entered an invalid email or password</span>
</div>
<mat-card id="invite" *ngIf="isInvite && inviteType == 'personal'">
  <span
    >You were invited to Live Data by {{invitation.invitedBy}} ({{invitation.invitedByEmail}}).
    Create an account to get started.</span
  >
</mat-card>
<mat-card id="signup" *ngIf="!isActionLogin && !inviteError">
  <div class="logo">
    <a routerLink="/">
      <img src="../../assets/img/imagotype2.svg" alt="logo" />
    </a>
  </div>
  <form [formGroup]="form" id="sign_up_complete">
    <h3>Finish creating your account</h3>
    <mat-form-field appearance="outline" *ngIf="!(invitation && invitation.inviteeName)">
      <mat-label>Your Name</mat-label>
      <input matInput formControlName="fullNameFormControl" placeholder="First & Last Name" />
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="!invitation">
      <mat-label>Email address</mat-label>
      <input
        matInput
        formControlName="emailFormControl"
        placeholder="e.g. eleanor@livedatatechnologies.com"
        autocomplete="off"
        (focus)="emailFocus=true;"
        (blur)="emailFocus=false;" />
      <div class="tooltip-container" *ngIf="isInvite && emailFocus">
        <span class="title">Warning</span>
        <div class="password-validation-container">
          <div class="validation">
            <span class="validation-text" [ngClass]="{'text-cancel': true }"
              >Invites are tied to the email address that received the invitation. Using a different
              email address will require a new invitation.</span
            >
          </div>
        </div>
      </div>
    </mat-form-field>
    <mat-form-field appearance="outline" style="position: relative;">
      <mat-label>Password</mat-label>
      <input
        matInput
        formControlName="passwordFormControl"
        (focus)="showValidationTooltip($event);"
        (blur)="isValidating=false;"
        (change)="update($event);"
        placeholder="e.g. &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
        type="password"
        autocomplete="off" />
      <div class="tooltip-container" *ngIf="isValidating">
        <span class="title">Password requirements: </span>
        <div class="password-validation-container">
          <div class="validation" *ngFor="let v of pValidation;">
            <div [ngClass]="{'cancel': !v.func(form), 'check': v.func(form) }"></div>
            <span
              class="validation-text"
              [ngClass]="{'text-cancel': !v.func(form), 'text-success': v.func(form) }"
              >{{v.text}}</span
            >
          </div>
        </div>
      </div>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        formControlName="confirmPasswordFormControl"
        placeholder="e.g. &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
        type="password"
        autocomplete="off" />
    </mat-form-field>
    <mat-error *ngIf="checkPasswords(form)"> Passwords do not match </mat-error>
    <!-- <mat-checkbox formControlName="termsOfUse" name="termsOfUse">Agreed to terms of use and privacy statements</mat-checkbox> -->
  </form>
  <div class="email-error signup-error" [ngClass]="{hide: !signUpEmailError }">
    Please use a company email to sign up
  </div>
  <div class="email-error signup-error" [ngClass]="{hide: !validationError }">
    Please check your password and try again
  </div>
  <button class="rounded-signup" (click)="onSubmit($event)">Get Started!</button>
  <div class="login" *ngIf="inviteType != 'moneyball_onboarding'">
    <a (click)="navigateToLogin()">Already a user? Log in</a>
  </div>
  <div class="password" *ngIf="inviteType != 'moneyball_onboarding'">
    <a (click)="forgotPassword()">Forgot password?</a>
  </div>
</mat-card>
<mat-card id="login" *ngIf="isActionLogin">
  <div class="logo">
    <a routerLink="/">
      <img src="../../assets/img/imagotype2.svg" alt="logo" />
    </a>
  </div>
  <form [formGroup]="loginform" (submit)="onSubmit($event)">
    <h3>Log In</h3>
    <mat-form-field appearance="outline">
      <mat-label>Email address</mat-label>
      <input
        matInput
        formControlName="email"
        placeholder="e.g. eleanor@livedatatechnologies.com"
        autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        formControlName="password"
        placeholder="e.g. &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
        [type]="show ? 'text' : 'password'"
        id="password"
        autocomplete="off" />
    </mat-form-field>
    <button class="rounded-signup" type="submit">Log In</button>
    <div class="error" *ngIf="error">Sorry, {{ error.error }}.</div>
  </form>
  <div class="password">
    <a (click)="forgotPassword()">Forgot password?</a>
  </div>
  <div class="supplementary">Don't have an account? Ask your administrator for an invite.</div>
  <div class="supplementary">
    <a href="/onboarding/moneyball">Or get started for free.</a>
  </div>
</mat-card>
<mat-card id="login" *ngIf="inviteError">
  <div class="logo">
    <a routerLink="/">
      <img src="../../assets/img/imagotype2.svg" alt="logo" />
    </a>
  </div>
  <img class="tw-h-10 tw-m-8" src="../../assets/icons/error.svg" alt="error" />
  <span
    class="error-message tw-text-red-700 tw-text-lg tw-font-medium tw-leading-6 tw-text-center tw-whitespace-pre-line">
    {{inviteErrorMessage}}
  </span>
</mat-card>
