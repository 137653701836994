<div
  class="builds-container tw-flex tw-items-center tw-flex-wrap tw-my-3"
  [ngClass]="{'tw-justify-end': !showQuickBuilds}">
  <div *ngIf="showQuickBuilds" class="tw-flex tw-flex-wrap tw-items-center">
    <h2 class="tw-text-sm tw-font-semibold tw-my-0">Quick Builds</h2>
    <button
      matTooltip="Quick Builds are pre-configured filters that you can apply to your search"
      matTooltipPosition="above"
      class="tw-mr-1">
      <mat-icon class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-leading-[21px] tw-cursor-pointer">
        info_outline
      </mat-icon>
    </button>
    <button
      *ngFor="let quickBuild of quickBuilds"
      mat-stroked-button
      color="primary"
      class="compact-btn tw-mr-3 tw-mb-1"
      (click)="setQuickBuildFilters(quickBuild.id)">
      {{quickBuild.name}}
      <mat-icon
        class="tw-text-[14px] tw-w-[14px] tw-h-[14px] tw-transform tw-rotate-[-30deg] tw-ml-1 tw-mb-1"
        style="--tw-translate-x: 0; --tw-translate-y: 0; --tw-skew-x: 0; --tw-skew-y: 0; --tw-scale-x: 1; --tw-scale-y: 1;">
        send
      </mat-icon>
      <mat-icon
        class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-ml-1 tw-mb-1"
        (click)="deleteQuickBuild(quickBuild.id); $event.stopPropagation()">
        delete_forever
      </mat-icon>
    </button>
    <button
      class="tw-font-medium tw-text-gray-800 tw-flex tw-items-center tw-text-xs tw-border tw-border-transparent tw-border-dotted hover:tw-rounded-sm focus:tw-rounded-sm hover:tw-text-primary_purple-600 hover:tw-border-primary_purple-600 focus:tw-text-primary_purple-600 focus:tw-border-primary_purple-600"
      (click)="toggleQuickBuilds()">
      <mat-icon class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-mr-1"> visibility_off </mat-icon>
      <span>Hide Quick Builds</span>
    </button>
  </div>

  <button
    *ngIf="!showQuickBuilds"
    class="tw-font-medium tw-text-primary_purple-600 tw-flex tw-items-center tw-text-sm tw-border tw-border-transparent tw-border-dotted hover:tw-border-primary_purple-600 hover:tw-rounded-sm focus:tw-rounded-sm focus:tw-border-primary_purple-600"
    (click)="toggleQuickBuilds()">
    <mat-icon class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-mr-1"> visibility </mat-icon>
    <span class="hover:tw-underline">Show Quick Builds</span>
  </button>
</div>
