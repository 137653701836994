import { Component, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import {
  CreateUserRequest,
  User,
  UsersService,
  UpdateUserStatusRequest,
} from 'ldt-identity-service-api';
import { CellClassParams, GridApi } from 'ag-grid-community';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { UserCreateDialogComponent } from './user-create-dialog/user-create-dialog.component';

@Component({
  selector: 'app-identity-users',
  templateUrl: './identity-users.component.html',
  styleUrls: ['./identity-users.component.scss'],
})
export class IdentityUsersComponent implements OnInit {
  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs = [
    { field: 'id', cellRenderer: 'loadingRenderer', maxWidth: 150 },
    { field: 'name', sort: 'asc' },
    { field: 'email' },
    { field: 'isValidated' },
    { field: 'status', filter: true, cellStyle: this.getCellColor.bind(this) },
    { field: 'source', filter: true },
    { field: 'createdAt' },
    { field: 'lastLoginAt' },
    {
      headerName: 'Reset PW',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.initiateReset.bind(this),
        label: 'Reset PW',
        display: 'compact',
      },
    },
    {
      headerName: 'Status',
      filter: false,
      maxWidth: 150,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.toggleStatus.bind(this),
        label: 'Toggle',
        display: 'compact',
      },
    },
  ];
  rowData: User[];

  private gridApi: GridApi;

  initiateReset(e: any) {
    let user: User = e.rowData;

    if (!user.email) return;
    this.userService.initiatePasswordReset({ email: user.email }).subscribe(
      (r) => {
        this.notify.success('Email sent. Please ask the user to check their inbox.');
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }
  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params: any) {
    // Default the status filter to active users only
    var instance = this.gridApi.getFilterInstance('status');
    instance?.setModel({ values: ['active'] });
    this.gridApi.onFilterChanged();
  }

  refreshing: boolean = true;

  constructor(
    private userService: UsersService,
    private dialog: MatDialog,
    private notify: NotificationService
  ) {}

  ngOnInit() {
    this.updateUsers();
  }

  toggleStatus(e: any) {
    let user: User = e.rowData;
    let body: UpdateUserStatusRequest = {
      status:
        user.status === 'active'
          ? UpdateUserStatusRequest.StatusEnum.Inactive
          : UpdateUserStatusRequest.StatusEnum.Active,
    };
    this.userService.updateUserStatus(user.id, body).subscribe({
      next: () => {
        this.updateUsers();
        this.notify.success('Changed their status');
      },
      error: () => {
        this.notify.error('Error changing status');
      },
    });
  }

  showCreateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(UserCreateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.createUser(data.name, data.email, data.password);
      }
    });
  }

  createUser(name: string, email: string, password: string) {
    // Use |any since the `source` parameter is intentionally not documented in the API spec
    let userBody: CreateUserRequest | any = {
      name: name.trim(),
      email: email.trim(),
      password: password,
    };
    userBody['source'] = 'ldt-angular-ui';
    this.userService.createUser(userBody).subscribe(
      (r) => {
        this.updateUsers();
        this.notify.success('User Created');
      },
      (err) => {
        this.notify.error('Failed to create user: ' + err.error);
      }
    );
  }

  updateUsers() {
    this.refreshing = true;
    this.userService.getUsers().subscribe(
      (res) => {
        this.rowData = res;
        this.refreshing = false;
      },
      () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      }
    );
  }

  getCellColor(params: CellClassParams) {
    if (params.data.status === 'inactive') return { backgroundColor: 'tomato' };

    return {};
  }
}
