import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import {
  SearchService,
  OpensearchDownloadRequest,
  SearchPersonsDataRequestData,
  PersonSearchFilter,
  CreateSearchReport,
  SearchReport,
} from 'ldt-dw-reader-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { ColumnCategory, ColumnType, DWColumn, JobFunctions, JobLevels } from '../dw-column';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FieldSelectorDialogComponent } from 'src/app/shared/field-selector-dialog/field-selector-dialog.component';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';

interface Filter {
  field: string;
  value: string | Object;
  type?: FilterType;
  match_type?: MatchType;
  job_group?: number;
}

interface DateRangePickerValue {
  startDate: Dayjs;
  endDate: Dayjs;
}

enum FilterType {
  Must = 'must',
  MustNot = 'must_not',
}

enum MatchType {
  Fuzzy = 'fuzzy',
  Exact = 'exact',
  Exists = 'exists',
}

@Component({
  selector: 'app-custom-search',
  templateUrl: './custom-search.component.html',
  styleUrls: ['./custom-search.component.scss'],
})
export class CustomSearchComponent implements OnInit {
  savedSearches: any[] = [];
  selectedSearch: any;
  newSavedSearchName: string;
  functionsEnum = JobFunctions;
  levelsEnum = JobLevels;
  selectedQuery: string;

  categories: ColumnCategory[] = [
    {
      name: 'default',
      displayName: 'Default',
      description: 'Default columns',
      displayColumn: 1,
    },
    {
      name: 'personal',
      displayName: 'Personal',
      description: 'Personal information',
      displayColumn: 1,
    },
    {
      name: 'current job',
      displayName: 'Current Job',
      description: 'Information about the current job',
      displayColumn: 2,
    },
    {
      name: 'past jobs',
      displayName: 'Past Jobs',
      description: 'Information about past jobs',
      displayColumn: 3,
    },
    {
      name: 'education',
      displayName: 'Education',
      description: 'Information about education',
      displayColumn: 1,
    },
    {
      name: 'metadata',
      displayName: 'Timestamps',
      description: 'Timestamps for this record',
      displayColumn: 1,
    },
  ];

  columns: DWColumn[] = [
    {
      name: 'company_change_detected_at',
      displayName: 'Company Change Detected',
      type: ColumnType.date,
      group: 'metadata',
    },
    {
      name: 'connections',
      displayName: 'Number of Connections',
      type: ColumnType.number,
      group: 'personal',
    },
    { name: 'country', displayName: 'Country', group: 'personal', isPopular: true },
    { name: 'employment_status', displayName: 'Employment Status', group: 'personal' },
    {
      name: 'created_at',
      displayName: 'Record Created',
      type: ColumnType.date,
      group: 'metadata',
    },
    { name: 'id', displayName: 'Person ID', group: 'default' },
    {
      name: 'info_change_detected_at',
      displayName: 'Change Detected',
      type: ColumnType.date,
      group: 'metadata',
    },
    {
      name: 'last_success_at',
      displayName: 'Last Success',
      type: ColumnType.date,
      group: 'metadata',
    },
    {
      name: 'ldt_created_at',
      displayName: 'LDT Created',
      type: ColumnType.date,
      group: 'metadata',
    },
    { name: 'linkedin', displayName: 'LinkedIn ID', group: 'personal' },
    { name: 'location', displayName: 'Location', group: 'personal', isPopular: true },
    { name: 'name', displayName: 'Full Name', group: 'personal' },
    { name: 'first_name', displayName: 'First Name', group: 'personal' },
    { name: 'last_name', displayName: 'Last Name', group: 'personal' },
    { name: 'cell_phone_number', displayName: 'Cell Phone', group: 'personal' },
    { name: 'position.company.address', displayName: 'Company Address', group: 'current job' },
    { name: 'position.company.country', displayName: 'Company Country', group: 'current job' },
    {
      name: 'position.company.employee_count',
      displayName: 'Company Employee Count',
      type: ColumnType.number,
      group: 'current job',
    },
    {
      name: 'position.company.founded_year',
      displayName: 'Company Founded Year',
      group: 'current job',
      type: ColumnType.number,
    },
    { name: 'position.company.group_id', displayName: 'Company Parent ID', group: 'current job' },
    {
      name: 'position.company.grouped_company_count',
      displayName: 'Company Number of Subsidiaries',
      group: 'current job',
      type: ColumnType.number,
    },
    { name: 'position.company.industry', displayName: 'Company Industry', group: 'current job' },
    { name: 'position.company.linkedin', displayName: 'Company LinkedIn ID', group: 'current job' },
    {
      name: 'position.company.linkedin_numeric_id',
      displayName: 'Company LinkedIn Numeric ID',
      group: 'current job',
    },
    {
      name: 'position.company.location',
      displayName: 'Company Location',
      group: 'current job',
      isPopular: true,
    },
    {
      name: 'position.company.naics_codes',
      displayName: 'Company NAICS codes',
      group: 'current job',
    },
    {
      name: 'position.company.name',
      displayName: 'Company Name',
      group: 'current job',
      isPopular: true,
    },
    { name: 'position.company.sic_codes', displayName: 'Company SIC codes', group: 'current job' },
    { name: 'position.company.ticker', displayName: 'Company Ticker', group: 'current job' },
    { name: 'position.company.type', displayName: 'Company Type', group: 'current job' },
    { name: 'position.company.domain', displayName: 'Company Domain', group: 'current job' },
    {
      name: 'position.direct_dial_number',
      displayName: 'Direct Dial Number',
      group: 'current job',
    },
    { name: 'position.email', displayName: 'Email', group: 'current job' },
    { name: 'position.email_status', displayName: 'Email Status', group: 'current job' },
    {
      name: 'position.ended_at',
      displayName: 'Timestamp for Job Ended',
      type: ColumnType.date,
      group: 'current job',
      isPopular: true,
    },
    {
      name: 'position.function',
      displayName: 'Job Function',
      type: ColumnType.jobfunction,
      group: 'current job',
    },
    {
      name: 'position.level',
      displayName: 'Job Level',
      type: ColumnType.joblevel,
      group: 'current job',
      isPopular: true,
    },
    { name: 'position.location', displayName: 'Location', group: 'current job', isPopular: true },
    {
      name: 'position.started_at',
      displayName: 'Timestamp for Job Started',
      type: ColumnType.date,
      group: 'current job',
      isPopular: true,
    },
    { name: 'position.title', displayName: 'Title', group: 'current job', isPopular: true },
    {
      name: 'position.metadata.started_at_inferred',
      displayName: 'position.metadata.started_at_inferred',
      type: ColumnType.boolean,
      group: 'current job',
    },
    {
      name: 'position.metadata.ended_at_inferred',
      displayName: 'position.metadata.ended_at_inferred',
      type: ColumnType.boolean,
      group: 'current job',
    },
    {
      name: 'position.metadata.started_at_year_only',
      displayName: 'position.metadata.started_at_year_only',
      type: ColumnType.boolean,
      group: 'current job',
    },
    {
      name: 'position.metadata.ended_at_year_only',
      displayName: 'position.metadata.ended_at_year_only',
      type: ColumnType.boolean,
      group: 'current job',
    },
    { name: 'jobs.company.address', displayName: 'Company Address', group: 'past jobs' },
    { name: 'jobs.company.country', displayName: 'Company Country', group: 'past jobs' },
    {
      name: 'jobs.company.employee_count',
      displayName: 'Company Employee Count',
      type: ColumnType.number,
      group: 'past jobs',
    },
    {
      name: 'jobs.company.founded_year',
      displayName: 'Company Founded Year',
      group: 'past jobs',
      type: ColumnType.number,
    },
    { name: 'jobs.company.group_id', displayName: 'Company Parent ID', group: 'past jobs' },
    {
      name: 'jobs.company.grouped_company_count',
      displayName: 'Company Number of Subsidiaries',
      group: 'past jobs',
      type: ColumnType.number,
    },
    { name: 'jobs.company.industry', displayName: 'Company Industry', group: 'past jobs' },
    { name: 'jobs.company.linkedin', displayName: 'Company LinkedIn ID', group: 'past jobs' },
    {
      name: 'jobs.company.linkedin_numeric_id',
      displayName: 'Company LinkedIn Numeric ID',
      group: 'past jobs',
    },
    { name: 'jobs.company.location', displayName: 'Company Location', group: 'past jobs' },
    { name: 'jobs.company.naics_codes', displayName: 'Company NAICS Codes', group: 'past jobs' },
    { name: 'jobs.company.name', displayName: 'Company Name', group: 'past jobs', isPopular: true },
    { name: 'jobs.company.sic_codes', displayName: 'Company SIC Codes', group: 'past jobs' },
    { name: 'jobs.company.ticker', displayName: 'Company Ticker', group: 'past jobs' },
    { name: 'jobs.company.type', displayName: 'Company Type', group: 'past jobs' },
    { name: 'jobs.company.domain', displayName: 'Company Domain', group: 'past jobs' },
    { name: 'jobs.direct_dial_number', displayName: 'Direct Dial Number', group: 'past jobs' },
    { name: 'jobs.email', displayName: 'Email', group: 'past jobs' },
    { name: 'jobs.email_status', displayName: 'Email Status', group: 'past jobs' },
    {
      name: 'jobs.ended_at',
      displayName: 'Timestamp for Job Ended',
      type: ColumnType.date,
      group: 'past jobs',
    },
    {
      name: 'jobs.function',
      displayName: 'Job Function',
      type: ColumnType.jobfunction,
      group: 'past jobs',
    },
    { name: 'jobs.level', displayName: 'Job Level', type: ColumnType.joblevel, group: 'past jobs' },
    { name: 'jobs.location', displayName: 'Location', group: 'past jobs' },
    {
      name: 'jobs.started_at',
      displayName: 'Timestamp for Job Started',
      type: ColumnType.date,
      group: 'past jobs',
      isPopular: true,
    },
    { name: 'jobs.title', displayName: 'Job Title', group: 'past jobs', isPopular: true },
    {
      name: 'jobs.metadata.started_at_inferred',
      displayName: 'jobs.metadata.started_at_inferred',
      type: ColumnType.boolean,
      group: 'past jobs',
    },
    {
      name: 'jobs.metadata.ended_at_inferred',
      displayName: 'jobs.metadata.ended_at_inferred',
      type: ColumnType.boolean,
      group: 'past jobs',
    },
    {
      name: 'jobs.metadata.started_at_year_only',
      displayName: 'jobs.metadata.started_at_year_only',
      type: ColumnType.boolean,
      group: 'past jobs',
    },
    {
      name: 'jobs.metadata.ended_at_year_only',
      displayName: 'jobs.metadata.ended_at_year_only',
      type: ColumnType.boolean,
      group: 'past jobs',
    },
    {
      name: 'jobs.metadata.original_company_lookup',
      displayName: 'jobs.metadata.original_company_lookup',
      type: ColumnType.string,
      group: 'past jobs',
    },
    {
      name: 'title_change_detected_at',
      displayName: 'Timestamp for Title Change Detected',
      type: ColumnType.date,
      group: 'metadata',
    },
    {
      name: 'updated_at',
      displayName: 'Timestamp for Last Update',
      type: ColumnType.date,
      group: 'metadata',
    },
    { name: 'education.degree', displayName: 'Education Degree Name', group: 'education' },
    {
      name: 'education.ended_at',
      displayName: 'Education End Date',
      type: ColumnType.date,
      group: 'education',
    },
    { name: 'education.field', displayName: 'Education Field', group: 'education' },
    { name: 'education.school', displayName: 'School Name', group: 'education' },
    {
      name: 'education.started_at',
      displayName: 'Education Start Date',
      type: ColumnType.date,
      group: 'education',
    },
  ];

  filters: Filter[] = [];
  returnType = 'data';
  pastJobs: number = 3;
  returnFields: any = {};

  constructor(
    private dwReader: SearchService,
    private notify: NotificationService,
    private dialog: MatDialog
  ) {
    this.columns.forEach((c) => (this.returnFields[c.name] = true));
  }

  ngOnInit(): void {
    this.loadSearchParams();
    this.loadSavedSearches();
  }

  files: any;
  results: any;
  queries: any;
  downloading: boolean = false;
  searching: boolean = false;
  confidentOnly: boolean = true;
  count: number;

  performSearch() {
    this.searching = true;
    this.saveSearchParams();
    Object.keys(this.returnFields).filter((k) => this.returnFields[k]);

    let searchBody: SearchPersonsDataRequestData = {
      filters: this.uiFiltersToSearchFilters(this.filters),
      past_jobs: this.pastJobs,
      return_fields: Object.keys(this.returnFields).filter((k) => this.returnFields[k]),
      fresh_only: this.confidentOnly,
    };

    let fileParam = this.files ? this.files[0] : undefined;

    this.dwReader.searchPersonsData(fileParam, searchBody).subscribe({
      next: (r: any) => {
        this.count = r.count;
        this.results = r.results;
        if (r.queries) {
          this.queries = r.queries;
          this.selectedQuery = r.queries[0];
        } else {
          this.queries = [r.query];
        }

        this.searching = false;
      },
      error: () => {
        this.notify.error('Error querying DW Reader');
        this.searching = false;
      },
    });
  }

  uploadFiles(files: any) {
    this.files = files;
  }

  toJson(o: any) {
    return JSON.stringify(o, null, 2);
  }

  downloadData() {
    this.downloading = true;
    let body: OpensearchDownloadRequest = {
      request: this.queries,
      past_jobs: this.pastJobs,
    };
    this.dwReader.opensearchDownload(body).subscribe({
      next: (res: any) => {
        var data = new Blob([res], { type: 'text/csv' });
        FileSaver.saveAs(data, 'dw-search-download-' + moment().format('YYYYMMDD-HHmmss') + '.csv');
        this.downloading = false;
      },
      error: (e) => {
        this.notify.error(e.message);
        this.downloading = false;
      },
    });
  }

  uiFiltersToSearchFilters(filters: Filter[]): PersonSearchFilter[] {
    let searchFilters: PersonSearchFilter[] = [];

    filters.forEach((f) => {
      if (!f.field || f.field === '') return;

      // Only include job_group if the field is a jobs field
      const jobGroupVal = f.field.startsWith('jobs.') ? f.job_group : undefined;

      switch (this.columns.find((c) => c.name === f.field)?.type || ColumnType.string) {
        case ColumnType.date:
          try {
            if (f.match_type === MatchType.Exists) {
              searchFilters.push({
                type: f.type || FilterType.Must,
                field: f.field,
                job_group: jobGroupVal,
                match_type: f.match_type,
              });
            } else {
              const start = dayjs((f.value as DateRangePickerValue).startDate).format('YYYY-MM-DD');
              const end = dayjs((f.value as DateRangePickerValue).endDate).format('YYYY-MM-DD');

              searchFilters.push({
                type: f.type || FilterType.Must,
                field: f.field,
                date_from: start,
                date_to: end,
                job_group: jobGroupVal,
                match_type: f.match_type,
              });
            }
          } catch (e) {
            this.notify.error('Error getting dates for ' + f.field);
          }
          break;
        case ColumnType.string:
          // If match type is exists, we don't need to send a value
          let stringVal = undefined;
          if (f.match_type !== MatchType.Exists) {
            stringVal = (f.value as string).split(',').map((v) => v.trim());
          }

          searchFilters.push({
            type: f.type || FilterType.Must,
            field: f.field,
            string_values: stringVal,
            job_group: jobGroupVal,
            match_type: f.match_type,
          });
          break;
        case ColumnType.number:
          searchFilters.push({
            type: f.type || FilterType.Must,
            field: f.field,
            number_min: parseInt((f.value as string) || '0') || 0,
            job_group: jobGroupVal,
            match_type: f.match_type,
          });
          break;
        case ColumnType.boolean:
          searchFilters.push({
            type: f.type || FilterType.Must,
            field: f.field,
            boolean_value: f.value === true,
            job_group: jobGroupVal,
            match_type: f.match_type,
          });
          break;
        default:
          const val2: string[] | undefined =
            f.match_type === MatchType.Exists
              ? undefined
              : typeof f.value === 'string'
                ? [f.value]
                : (f.value as string[]);
          searchFilters.push({
            type: f.type || FilterType.Must,
            field: f.field,
            string_values: val2,
            job_group: jobGroupVal,
            match_type: f.match_type,
          });
          break;
      }
    });

    return searchFilters;
  }

  // Persist and load search params in local storage
  saveSearchParams() {
    localStorage.setItem('dw-search-params', this.serializeParameters());
  }

  loadSearchParams() {
    try {
      const saved = JSON.parse(localStorage.getItem('dw-search-params') || '{}');
      this.parseParameters(saved);
    } catch (e) {
      console.log('Error loading search params');
    }
  }

  parseParameters(params: any) {
    if ('type' in params) this.returnType = params?.type;
    if ('pastJobs' in params) this.pastJobs = params?.pastJobs;
    if ('returnFields' in params) this.returnFields = params?.returnFields;
    if ('filters' in params) {
      let filtersToSave = params?.filters;

      // Fix the date formats for the date picker
      filtersToSave.map((f: any) => {
        if (
          this.columns.find((c) => c.name === f.field)?.type === ColumnType.date &&
          f.match_type !== MatchType.Exists
        ) {
          f.value.startDate = formatDate(f.value.startDate, 'MM/dd/yyyy', 'en');
          f.value.endDate = formatDate(f.value.endDate, 'MM/dd/yyyy', 'en');
        }
        return f;
      });
      this.filters = filtersToSave;
    }
    if ('confidentOnly' in params) {
      this.confidentOnly = params?.confidentOnly;
    } else {
      // If not there, it was saved before we had this option, so should be false
      this.confidentOnly = false;
    }
  }

  serializeParameters(): string {
    const searchParams = {
      type: this.returnType,
      pastJobs: this.pastJobs,
      returnFields: this.returnFields,
      filters: this.filters,
      confidentOnly: this.confidentOnly,
    };

    return JSON.stringify(searchParams);
  }

  // Saved Searches
  loadSavedSearches() {
    this.dwReader.getSearchReports().subscribe({
      next: (r: SearchReport[]) => {
        this.savedSearches = r.filter((s) => {
          try {
            return s.parameters && !JSON.parse(s.parameters).quickBuild;
          } catch (e) {
            return false;
          }
        });
      },
      error: () => {
        this.notify.error('Error getting saved searches');
      },
    });
  }

  loadSavedSearch() {
    if (!this.selectedSearch) return;
    const saved = this.savedSearches.find((s) => s.id == this.selectedSearch);
    if (saved) {
      try {
        this.parseParameters(JSON.parse(saved.parameters));
        this.notify.success('Loaded saved search');
      } catch (e) {
        this.notify.error('Error loading saved search');
      }
    }
  }

  saveSearch() {
    const newSearchReport: CreateSearchReport = {
      name: this.newSavedSearchName,
      parameters: this.serializeParameters(),
    };
    this.dwReader.createSearchReport(newSearchReport).subscribe({
      next: () => {
        this.notify.success('New search saved');
        this.loadSavedSearches();
      },
      error: () => {
        this.notify.error('Error saving search');
        this.savedSearches.push(newSearchReport);
      },
    });
  }

  deleteSearch() {
    if (!this.selectedSearch) return;
    const saved = this.savedSearches.find((s) => s.id == this.selectedSearch);

    const confirmDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm Search Deletion',
        message:
          "Are you sure you want to delete the search '" +
          saved.name +
          "' by " +
          saved.created_by +
          '? This action is unrecoverable!',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result == true) {
        this.dwReader.deleteSearchReport(saved.id).subscribe({
          next: () => {
            this.notify.success('Report deleted');
            this.loadSavedSearches();
          },
          error: () => {
            this.notify.error('Error deleting report');
          },
        });
      }
    });
  }

  // Filter list
  addFilter() {
    this.filters.push({ field: '', value: '', type: FilterType.Must, match_type: MatchType.Fuzzy });
  }
  removeFilter(i: number) {
    this.filters.splice(i, 1);
  }

  // UI helpers
  showSelectorModal(evt: any, i: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '1200px';
    dialogConfig.data = { categories: this.categories, fields: this.columns };
    const dialogRef = this.dialog.open(FieldSelectorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.filters[i].field = data;
        this.filters[i].match_type = this.matchTypesAsObject(data)[0];
      }
    });
  }
  showSelectorModalMulti() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '1200px';
    dialogConfig.data = {
      categories: this.categories,
      fields: this.columns,
      multi: true,
      returnFields: this.returnFields,
    };
    const dialogRef = this.dialog.open(FieldSelectorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.returnFields = data;
      }
    });
  }
  columnByName(name: string) {
    return this.columns.find((c) => c.name == name);
  }
  levelsAsObject() {
    return Object.values(JobLevels);
  }
  functionsAsObject() {
    return Object.values(JobFunctions);
  }
  typesAsObject() {
    return Object.values(FilterType);
  }
  matchTypesAsObject(field: any) {
    let values = Object.values(MatchType);
    const col = this.columns.find((c) => c.name === field);

    // Date fields don't use `exact` match type
    if (col && col.type === ColumnType.date) {
      values = values.filter((v) => v !== MatchType.Exact);
    }

    // Enum and boolean fields don't use `fuzzy` match type
    if (
      col &&
      (col.type === ColumnType.jobfunction ||
        col.type === ColumnType.joblevel ||
        col.type === ColumnType.boolean)
    ) {
      values = values.filter((v) => v !== MatchType.Fuzzy);
    }

    return values;
  }
  getFieldGroups(): any[] {
    return [...new Set(this.columns.map((c) => c.group))];
  }
  columnsForGroup(group: string): any[] {
    return this.columns.filter((c) => c.group === group);
  }
  getReturnFieldsCount(): number {
    return Object.keys(this.returnFields).filter((f) => this.returnFields[f]).length;
  }
  getFilterPanelDescription(): string {
    const numToList = 3;
    let desc = this.filters
      .slice(0, numToList)
      .map((f) => f.field)
      .join(', ');
    if (this.filters.length > numToList) {
      desc += ', and ' + (this.filters.length - numToList) + ' more...';
    }
    return desc;
  }
}
