<div class="actionContainer tw-flex tw-items-center tw-mt-4 tw-mb-3">
  <button
    mat-raised-button
    [disabled]="!canEdit || !areRowsSelected()"
    color="warn"
    (click)="deleteSelectedContacts()">
    Delete Selected Contacts
  </button>
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshData()">
    Refresh
  </button>
  <button
    mat-raised-button
    [class.spinner]="downloading"
    [disabled]="downloading"
    [matMenuTriggerFor]="downloadmenu">
    Download Contacts
    <mat-menu #downloadmenu="matMenu">
      <a mat-menu-item (click)="downloadView();">Current View</a>
      <a mat-menu-item (click)="downloadAll();">All Contacts</a>
    </mat-menu>
  </button>
  <button *ngIf="isAdmin | async" mat-raised-button (click)="inspect()">Inspect</button>
  <button *ngIf="isAdmin | async" mat-raised-button (click)="find()">Find</button>
  <button
    *ngIf="(isAdmin | async) || (orgHasReinit && canAdmin)"
    mat-raised-button
    [class.spinner]="reiniting"
    [disabled]="reiniting"
    (click)="reinitLedger()">
    Re-initialize Contacts
  </button>
  <span class="tw-ml-3">
    <a
      target="_blank"
      href="https://docs.gotlivedata.com/docs/understanding-ledger-data-fields"
      class="tw-text-primary_purple-500 tw-text-sm hover:tw-underline">
      <mat-icon class="info-icon">info</mat-icon>
      Understanding the data fields in your ledger
    </a>
  </span>
  <span class="ledgerSize tw-text-sm ">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="20"
      *ngIf="countUpdating; else contactCount"></mat-progress-spinner>
    <ng-template #contactCount>
      <span class="tw-font-semibold">{{viewCount | number}}</span>
    </ng-template>
    <span class="tw-ml-2">Matching Contacts</span>
  </span>
</div>
<div class="container ag-theme-balham" width="100%">
  <ag-grid-angular
    style="height: 100%; width: 100%"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [rowModelType]="rowModelType"
    [cacheBlockSize]="cacheBlockSize"
    [components]="components"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    [sideBar]="sideBar"
    [getRowId]="getRowId"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    [serverSideDatasource]="serverSideDatasource"
    (displayedColumnsChanged)="saveColumnState()">
  </ag-grid-angular>
</div>
