import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ServiceAccountCreateDialogComponent } from '../service-account-create-dialog/service-account-create-dialog.component';

@Component({
  selector: 'app-service-create-dialog',
  templateUrl: './service-create-dialog.component.html',
  styleUrls: ['./service-create-dialog.component.scss'],
})
export class ServiceCreateDialogComponent {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ServiceAccountCreateDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
