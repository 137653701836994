import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-sa-new-secret-dialog',
  templateUrl: './sa-new-secret-dialog.component.html',
  styleUrls: ['./sa-new-secret-dialog.component.scss'],
})
export class SaNewSecretDialogComponent implements OnInit {
  data: any;

  constructor(
    private dialogRef: MatDialogRef<SaNewSecretDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private notify: NotificationService,
    private _clipboardService: ClipboardService
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    this._clipboardService.copyResponse$.subscribe((re) => {
      if (re.isSuccess) {
        this.notify.success('Copied to clipboard', 2000);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
