export interface DWColumn {
  name: string;
  displayName: string;
  type?: ColumnType;
  group?: string;
  isPopular?: boolean;
}

export enum ColumnType {
  string = 'string',
  joblevel = 'joblevel',
  jobfunction = 'jobfunction',
  date = 'date',
  number = 'number',
  boolean = 'boolean',
  companySearch = 'companySearch',
}

export interface ColumnCategory {
  name: string;
  displayName: string;
  description: string;
  displayColumn: number;
}

export enum JobLevels {
  CTeam = 'C-Team',
  VP = 'VP',
  Director = 'Director',
  Manager = 'Manager',
  Staff = 'Staff',
  Consultant = 'Consultant',
}

export enum JobFunctions {
  Banking = 'Banking and Wealth Management',
  Business = 'Business Management',
  Consulting = 'Consulting',
  Education = 'Education',
  Engineering = 'Engineering',
  Finance = 'Finance and Administration',
  Healthcare = 'Healthcare',
  Human = 'Human Resources',
  Information = 'Information Technology',
  Legal = 'Legal',
  Marketing = 'Marketing and Product',
  Operations = 'Operations',
  Program = 'Program and Project Management',
  Publishing = 'Publishing, Editorial and Reporting',
  Quality = 'Quality',
  Real = 'Real Estate',
  Risk = 'Risk, Safety, Compliance',
  Sales = 'Sales and Support',
}
