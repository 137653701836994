<h2 mat-dialog-title>Create Quick Build</h2>

<mat-dialog-content class="tw-mb-6">
  <form [formGroup]="form" class="tw-space-y-4">
    <mat-form-field appearance="outline" class="tw-w-full">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
      <mat-error *ngIf="form.get('name')?.hasError('required')">Name is required</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Cancel</button>
  <button mat-raised-button [disabled]="!form.valid" color="primary" (click)="save()">
    Create
  </button>
</mat-dialog-actions>
